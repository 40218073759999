
import React from 'react';
import ImageGallery from 'react-image-gallery';
import Prismic from 'prismic-javascript'
import { Date, Link, RichText } from 'prismic-reactjs'

const apiEndpoint = 'https://brigit-aklin.cdn.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository
 
const client = Prismic.client(apiEndpoint)

const images = [
  {
    original: 'images_neu/24_SchmuckForum_S1-033.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-084.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-106.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-131.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-118.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-023.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-122.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-126.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-050.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-046.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-086.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-089.jpg',
  },  
  {
    original: 'images_neu/24_SchmuckForum_S1-111.jpg',
  },
  {
    original: 'images_neu/24_SchmuckForum_S1-114.jpg',
  },
  {
    original: 'images_alt/bild1.gif',
  },  
  {
    original: 'images_alt/bild2.gif',
  },  
  {
    original: 'images_alt/bild3.gif',
  },  
  {
    original: 'images_alt/bild4.gif',
  },  
  {
    original: 'images_alt/bild7.gif',
  },
  {
    original: 'images_alt/image1.gif',
  },
];

class Gallery extends React.Component {
  render() {
    return (
      <div id="gallery">
        <ImageGallery 
          items={images} 
          slideInterval={50000} 
          showThumbnails={false} 
          showPlayButton={false} 
          autoPlay 
          slideDuration={700}
          sizes={"80vh"}
          />
      </div>
    );
  }
}

class Footer extends React.Component {
  render() {
    return (
      <div id="footerView" style={{margin: 50}}>
        <div style={{textAlign: 'center', width: '100%'}}> 
          © 2019 BY BRIGIT AKLIN
        </div>
      </div>
    );
  }
}

class Contact extends React.Component {

  state = {
    hours: null
  }

  async componentDidMount() {
    const response = await client.query(
      Prismic.Predicates.at('document.type', 'hours')
    )
    this.setState({'hours': response && response.results && response.results[0]});
  }

  renderOpeningHoursLeft() {
    if(!this.state.hours) return null;
    return RichText.render(this.state.hours.data.left)
  }

  renderOpeningHoursRight() {
    if(!this.state.hours) return null;
    return RichText.render(this.state.hours.data.right)
  }

  render() {
    console.log(this.state);
    return (
      <div id="contactView">
        <div style={{textAlign: 'center'}}>
        <br/>
        <br/>
          <h1>KONTAKT</h1>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{flex: 3, textAlign: 'right', paddingRight: 20}}>
            <p> 
              <a href="http://www.schmuckforum.ch" target="_blank">SCHMUCKFORUM</a>
            </p>
          </div>
          <div style={{flex: 3}}>
              <p>BRIGIT AKLIN</p>
              <p>ZOLLIKERSTRASSE 12</p>
              <p>8008 ZÜRICH</p>
              <a href="mailto:brigit.aklin@bluewin.ch" target="_top">brigit.aklin@bluewin.ch</a><br/><br/>
              <p> 
                <a href="https://www.instagram.com/schmuckforum/" target="_blank">
                  <img src="assets/insta.png" style={{filter: 'invert(1)', width: 30}} /> 
                </a>
              </p>
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{flex: 3, textAlign: 'right', paddingRight: 20}}>
            {this.renderOpeningHoursLeft()}
          </div>
          <div style={{flex: 3}}>
            {this.renderOpeningHoursRight()}
          </div>
        </div>
        <div style={{textAlign: 'center', marginTop: 40}}>
          <iframe 
            style={{width: '90%', height: '400px'}} 
            id="gmap_canvas" 
            src="https://maps.google.com/maps?q=schmuckforum&t=&z=15&ie=UTF8&iwloc=&output=embed" 
            frameBorder="0" 
            scrolling="no" 
            marginHeight="0" 
            marginWidth="0"></iframe>
        </div>
      </div>
    );
  }
}

class App extends React.Component {

  state = {
    hours: null
  }

  async componentDidMount() {
    const response = await client.query(
      Prismic.Predicates.at('document.type', 'hours')
    )
    this.setState({'hours': response && response.results && response.results[0]});
  }

  renderOpeningHoursLeft() {
    if(!this.state.hours) return null;
    return RichText.render(this.state.hours.data.left)
  }

  renderOpeningHoursRight() {
    if(!this.state.hours) return null;
    return RichText.render(this.state.hours.data.right)
  }

  render() {
    return (
      <div>
        <div id="helloView" style={{
          height: '100vh', 
          backgroundColor: '#222222', // #282c34', 
          textAlign: 'center', 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <span>
            <h1 style={{color: 'white'}}>BRIGIT AKLIN</h1> 
            <h4 style={{color: 'white', marginTop: 50}}>SCHMUCKFORUM ZÜRICH</h4>
          </span>


          <a href="#" style={{position: 'absolute', bottom: '0vh'}} onClick={(e) => {
            e.preventDefault();
            document.querySelector('#gallery').scrollIntoView({ behavior: 'smooth' });
          }}><img src="assets/chevron-down.svg" style={{width: 100, color: 'white', filter: 'invert(1)'}}/></a>
        
        </div>
        <div style={{
          height: '0vh',
          backgroundColor: '#222222',
          textAlign: 'center'
        }}>
        </div>
        <div id="gallery-container">
          <Gallery />
          <a href="#" style={{position: "absolute", bottom: '0vh'}} onClick={(e) => {
            e.preventDefault();
            document.querySelector('#contactView').scrollIntoView({ behavior: 'smooth' });
          }}><img src="assets/chevron-down.svg" style={{width: 100, marginLeft: -50, color: 'white', filter: 'invert(1)'}}/></a>
        </div>
        <Contact />
        <Footer />
      </div>
    );
  }
}
export default App;
